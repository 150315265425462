






















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoCard extends Vue {
  @Prop({
    default: 'info-light',
    type: String,
  })
  color!: string

  @Prop({
    default: 'info',
    type: String,
  })
  borderColor!: string

  @Prop({
    default: '',
    type: String,
  })
  title!: string

  @Prop({
    type: Boolean,
  })
  outlined!: boolean

  @Prop({
    type: Boolean,
  })
  flat!: boolean

  @Prop({
    default: 12,
    type: [Number, String],
  })
  padding!: string | number
}
