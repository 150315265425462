


























































































import InfoCard from '../components/InfoCard.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import { Vue, Component } from 'vue-property-decorator'
import { SignInOpts } from '@aws-amplify/auth/lib-esm/types'

import { SIGN_IN } from '@/store/auth'
import { MARKETING_URL } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Sign in',
      }
    },
    style: [
      {
        type: 'text/css',
        inner: `.v-main {
          background: url('/img/seller-background.svg');
          background-size: 650%;
          background-position: 5.5% 1.5%;
          background-attachment: fixed;
        }`,
      },
    ],
  },
  components: {
    ValidationMessages,
    PasswordInput,
    InfoCard,
  },
})
export default class SignIn extends Vue {
  MARKETING_URL = MARKETING_URL

  loading = false
  showPassword = false

  signInParams = {
    username: '',
    password: '',
  } as SignInOpts
  signInErrors: string[] = []

  async signIn() {
    this.loading = true
    await this.$store
      .dispatch(SIGN_IN, this.signInParams)
      .then(() => {
        // TODO: sellers to releases dashboard buyers somewhere else?
        this.$router.push('/releases')
      })
      .catch(
        // TODO: handle "needs password reset" flow
        err => {
          if (err.code === 'UserNotConfirmedException') {
            this.$router.push({
              path: '/unconfirmed',
              query: {
                email: this.signInParams.username,
              },
            })
          } else {
            this.signInErrors = ['The username or password is incorrect']
          }
        },
      )

    this.loading = false
  }
}
